export const ArrowLeft = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10 12L6 8L10 4" stroke="currentColor" />
  </svg>
);

export const ArrowRight = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6 4L10 8L6 12" stroke="currentColor" />
  </svg>
);

export const ArrowDoubleLeft = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9 12L5 8L9 4" stroke="currentColor" />
    <path d="M12 12L8 8L12 4" stroke="currentColor" />
  </svg>
);

export const ArrowDoubleRight = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7 4L11 8L7 12" stroke="currentColor" />
    <path d="M4 4L8 8L4 12" stroke="currentColor" />
  </svg>
);
