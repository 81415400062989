export const Funds = () => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 1V1.5C4 2.88071 2.88071 4 1.5 4H1V8H1.54138C2.92209 8 4.04138 9.11929 4.04138 10.5V11H16V10.5C16 9.11929 17.1193 8 18.5 8H19V4H18.5C17.1193 4 16 2.88071 16 1.5V1H4ZM3 1H2.5C1.67157 1 1 1.67157 1 2.5V3H1.5C2.32843 3 3 2.32843 3 1.5V1ZM3.04138 11V10.5C3.04138 9.67157 2.36981 9 1.54138 9H1V9.5C1 10.3284 1.67157 11 2.5 11H3.04138ZM3.72515 12C3.66827 12.0225 3.60627 12.0349 3.54138 12.0349C3.4765 12.0349 3.4145 12.0225 3.35761 12H2.5C1.11929 12 0 10.8807 0 9.5V2.5C0 1.11929 1.11929 0 2.5 0H17.5C18.8807 0 20 1.11929 20 2.5V9.5C20 10.8807 18.8807 12 17.5 12H3.72515ZM17 11H17.5C18.3284 11 19 10.3284 19 9.5V9H18.5C17.6716 9 17 9.67157 17 10.5V11ZM17 1V1.5C17 2.32843 17.6716 3 18.5 3H19V2.5C19 1.67157 18.3284 1 17.5 1H17ZM10 10C7.79086 10 6 8.20914 6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6C14 8.20914 12.2091 10 10 10ZM10 9C11.6569 9 13 7.65685 13 6C13 4.34315 11.6569 3 10 3C8.34315 3 7 4.34315 7 6C7 7.65685 8.34315 9 10 9ZM0.5 14C0.223858 14 0 13.7761 0 13.5C0 13.2239 0.223858 13 0.5 13H19.5C19.7761 13 20 13.2239 20 13.5C20 13.7761 19.7761 14 19.5 14H0.5ZM0.5 16C0.223858 16 0 15.7761 0 15.5C0 15.2239 0.223858 15 0.5 15H19.5C19.7761 15 20 15.2239 20 15.5C20 15.7761 19.7761 16 19.5 16H0.5Z"
      fill="currentColor"
    />
  </svg>
);
