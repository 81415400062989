import twMerge from '../../../utils/twMerge';
import Check from './Check';

const CheckBox = ({
  checked,
  className,
  label,
  containerClassName,
  children,
  textSize = 'small',
  noCenterAlign,
  ...props
}) => {
  return (
    <label
      className={twMerge(
        'flex gap-2 cursor-pointer group',
        !noCenterAlign && 'items-center',
        containerClassName
      )}
    >
      <div className="p-1">
        <label
          className={twMerge(
            'flex items-center justify-center cursor-pointer w-4 h-4 rounded-sm border border-loblolly group-hover:border-jungleGreen',
            checked && 'border-jungleGreen',
            className
          )}
        >
          <input
            type="checkbox"
            className="hidden"
            checked={checked}
            {...props}
          />
          {checked && <Check />}
        </label>
      </div>
      <div className={twMerge(textSize === 'small' && 'text-sm')}>
        {label || children}
      </div>
    </label>
  );
};

export default CheckBox;
