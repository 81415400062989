import { Fragment } from 'react';
import TableHeader from './TableHeader';

const Table = ({ columns, data, onSort, renderRow, showSortIcons }) => {
  const columnIncludesAction = columns.find(
    (column) => column.label === 'Action'
  );

  const gridColumns = columnIncludesAction
    ? 'grid-cols-[repeat(calc(var(--length)-1),minmax(min-content,1fr))_max-content]'
    : `grid-cols-[repeat(var(--length),minmax(min-content,1fr))]`;

  return (
    <section
      className={`grid ${gridColumns} hide-scroll-bar overflow-x-scroll whitespace-nowrap`}
      style={{ '--length': columns.length }}
    >
      <TableHeader
        columns={columns}
        onSort={onSort}
        showSortIcons={showSortIcons}
      />
      {data.map((item, index) => (
        <Fragment key={index}>{renderRow(item)}</Fragment>
      ))}
    </section>
  );
};

export default Table;
