export const Logout = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.70711 9L4.85355 12.1464C5.04882 12.3417 5.04882 12.6583 4.85355 12.8536C4.65829 13.0488 4.34171 13.0488 4.14645 12.8536L0.146447 8.85355C-0.0488155 8.65829 -0.0488155 8.34171 0.146447 8.14645L4.14645 4.14645C4.34171 3.95118 4.65829 3.95118 4.85355 4.14645C5.04882 4.34171 5.04882 4.65829 4.85355 4.85355L1.70711 8H12.5C12.7761 8 13 8.22386 13 8.5C13 8.77614 12.7761 9 12.5 9H1.70711ZM7.5 1C7.22386 1 7 0.776142 7 0.5C7 0.223858 7.22386 0 7.5 0H15.5C16.8807 0 18 1.11929 18 2.5V15.5C18 16.8807 16.8807 18 15.5 18H7.5C7.22386 18 7 17.7761 7 17.5C7 17.2239 7.22386 17 7.5 17H15.5C16.3284 17 17 16.3284 17 15.5V2.5C17 1.67157 16.3284 1 15.5 1H7.5Z"
      fill="currentColor"
    />
  </svg>
);
