// USD Formats
const USDCurrencyFormat = {
  style: 'currency',
  currency: 'USD',
};

const USDCurrencyFormatCompact = {
  ...USDCurrencyFormat,
  notation: 'compact',
};

export const i18nUSDCurrencyFormat = new Intl.NumberFormat(
  'en-US',
  USDCurrencyFormat
);
export const i18nUSDCurrencyCompactFormat = new Intl.NumberFormat(
  'en-US',
  USDCurrencyFormatCompact
);

// NGN Formats
const NGNCurrencyFormat = {
  style: 'currency',
  currency: 'NGN',
  currencyDisplay: 'symbol',
};

const NGNCurrencyFormatCompact = {
  ...NGNCurrencyFormat,
  notation: 'compact',
};

export const i18nNGNCurrencyFormat = new Intl.NumberFormat(
  'en-NG',
  NGNCurrencyFormat
);
export const i18nNGNCurrencyCompactFormat = new Intl.NumberFormat(
  'en-NG',
  NGNCurrencyFormatCompact
);
export const nairaCurrencyFormat = {
  style: 'currency',
  currency: 'NGN',
  currencyDisplay: 'symbol',
};

/**
 * formatMoney method
 * @returns string
 * @param {amount} amount of money
 * @param {currency} currency of money
 */
export const formatMoney = (amount, currency) => {
  return currency?.toLowerCase() === 'usd'
    ? i18nUSDCurrencyFormat.format(amount ?? 0)
    : currency?.toLowerCase() === 'ngn'
      ? i18nNGNCurrencyFormat.format(amount ?? 0)
      : '0.00';
};
