import { useFormik } from 'formik';
import { object, number, date, boolean } from 'yup';
import MoneyInput from '../../components/Inputs/MoneyInput';
import DateInput from '../../components/Inputs/DateInput';
import NewCheckbox from '../../components/NewCheckbox';
import { Button } from '../../components/Button';
import { useAppMutation, useAppQuery } from '../../hooks/useAppQuery';
import { Toast } from '../../helpers/toastify.helpers';
import Table from '../../components/Table';
import { exchangeRateTableHeadings } from '../../utils/exchangeRate';
import ExchangeRateRow from './ExchangeRateRow';
import { formatMoney } from '../../utils/currency';
import { useQueryClient } from 'react-query';
import Rate from '../../utils/assets/Rate';
import Loader from '../../utils/assets/Loader';
import Pagination from '../../components/Pagination';
import { useEffect, useState } from 'react';

const schema = object().shape({
  buy: number().required('Buy Amount is required.'),
  sell: number().required('Sell Amount is required.'),
  date: date().required('Date is required'),
  telegram: boolean(),
});

const ExchangeRate = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { data: exchangeRates, isLoading: isLoadingRates } = useAppQuery(
    [`exchange-rates`, limit, page],
    {
      url: `/v1/exchanges?limit=${limit}&offset=${page}`,
    }
  );
  const { data: currentRate, error: currentRateError } = useAppQuery(
    `current-rate`,
    {
      url: 'v1/exchanges/latest',
    }
  );

  useEffect(() => {
    setPage(0);
  }, [limit]);

  const currentBuy = currentRateError ? 0 : currentRate?.data?.buy ?? 0;
  const currentSell = currentRateError ? 0 : currentRate?.data?.sell ?? 0;

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useAppMutation(
    {
      url: 'v1/exchanges',
      method: 'POST',
    },
    {
      onSettled: async (_, error) => {
        if (error) {
          const { message } = error.response.data;

          Toast({
            message,
            type: 'error',
          });

          return;
        }

        Toast({
          message: `Exchange rate updated successfully`,
          type: 'success',
        });
        await Promise.all([
          queryClient.invalidateQueries('current-rate'),
          queryClient.invalidateQueries('exchange-rates'),
        ]);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      buy: '',
      sell: '',
      date: new Date(),
      telegram: false,
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      mutate(values);
      resetForm();
    },
  });

  return (
    <section className="mt-[88px] mb-14 xl:mt-8 mx-4 lg:mx-8">
      <div className="bg-mischka max-w-[488px] mx-auto py-6 px-4 md:px-8">
        <h3 className="font-bold text-2xl text-center mb-6">Exchange Rates</h3>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col md:flex-row gap-6 mb-4">
            <div className="flex-1">
              <label className="text-blueWood inline-block mb-2" htmlFor="buy">
                Buy Rate
              </label>
              <MoneyInput
                name="buy"
                id="buy"
                placeholder="Enter Rate"
                onValueChange={(value) => formik.setFieldValue('buy', value)}
                value={formik.values.buy}
                currency="NGN"
                onBlur={formik.handleBlur}
                errored={formik.touched.buy && formik.errors.buy}
                errorMessage={formik.errors.buy}
              />
              <span className="text-xs block text-jungleGreen text-right md:text-left">
                Current Bid - {formatMoney(currentBuy ?? 0, 'NGN')}
              </span>
            </div>
            <div className="flex-1">
              <label className="text-blueWood inline-block mb-2" htmlFor="sell">
                Sell Rate
              </label>
              <MoneyInput
                name="sell"
                id="sell"
                placeholder="Enter Rate"
                onValueChange={(value) => formik.setFieldValue('sell', value)}
                value={formik.values.sell}
                currency="NGN"
                onBlur={formik.handleBlur}
                errored={formik.touched.sell && formik.errors.sell}
                errorMessage={formik.errors.sell}
              />
              <span className="block text-xs text-right text-valencia">
                Current Ask - {formatMoney(currentSell ?? 0, 'NGN')}
              </span>
            </div>
          </div>
          <DateInput
            label="Date"
            name="date"
            maxDate={new Date()}
            selected={new Date(formik.values.date)}
            value={formik.values.date}
            onChange={(value) => formik.setFieldValue('date', value)}
            onBlur={formik.handleBlur}
            errored={formik.touched.date && formik.errors.date}
            errorMessage={formik.errors.date}
          />
          <div className="mt-4 mb-6">
            <NewCheckbox
              label="Send a notification to Telegram?"
              checked={formik.values.telegram}
              onChange={() =>
                formik.setFieldValue('telegram', !formik.values.telegram)
              }
            />
          </div>

          <Button
            className="w-full"
            type="submit"
            loading={isLoading}
            disabled={!formik.isValid}
          >
            Update Rate
          </Button>
        </form>
      </div>

      <section>
        <h3 className="font-bold text-2xl mb-4 mt-10">Rate History</h3>
        {isLoadingRates ? (
          <Loader />
        ) : exchangeRates?.data.length > 0 ? (
          <>
            <Table
              columns={exchangeRateTableHeadings}
              data={exchangeRates?.data || []}
              renderRow={(item) => <ExchangeRateRow data={item} />}
            />
            <Pagination
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              hasMorePages={exchangeRates?.next ?? false}
            />
          </>
        ) : (
          <section className="grid place-content-center w-full">
            <div className="py-6 flex justify-center items-center flex-col">
              <div className="bg-mischka p-2">
                <Rate />
              </div>

              <p className="text-shuttlegray text-xs mt-2 text-center">
                You haven’t saved any exchange rates yet
              </p>
            </div>
          </section>
        )}
      </section>
    </section>
  );
};

export default ExchangeRate;
