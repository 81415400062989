import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/Auth';

const RestrictedRoutes = () => {
  const { auth } = useAuth();
  const location = useLocation();

  if (auth.token) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return <Navigate to="/login" />;
};

export default RestrictedRoutes;
