import React from 'react';

const Notification = ({ message }) => {
  return (
    <h3 className="text-base font-normal text-ebonyclay w-full my-1 pl-2.5">
      {message}
    </h3>
  );
};

export default Notification;
