import axios from 'axios';

let token = '';

/**
 * @returns {Object} axiosInstance
 */
const http = () => {
  if (typeof localStorage !== 'undefined') {
    token = localStorage.getItem('jwtToken');
  }
  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      token: `${token}`,
      accept: 'application/json',
      'Content-type': 'application/json; charset=UTF-8',
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        // window.location.replace('/logout');
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default http;
