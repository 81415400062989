import SelectInput from '../SelectInput';
import { ArrowRight } from './Arrows';
import MiniDateInput from './MiniDateInput';
import {
  dateRanges,
  quickRangeOptions,
  useRangeDateInput,
} from './RangeDateInputContext';

const RangeHeader = () => {
  const { setStartDate, setEndDate, setOpen, quickRange, setQuickRange } =
    useRangeDateInput();

  if (!setOpen) return null;
  return (
    <div className="text-sm text-left">
      <div>
        <label className="mb-2 block text-blueWood">Quick Range</label>
        <SelectInput
          placeholder="All year"
          options={quickRangeOptions}
          value={quickRange}
          onChange={(selected) => {
            if (!selected) return;
            const { start, end } = dateRanges[selected.value];
            setStartDate(start);
            setEndDate(end);
            setQuickRange(selected);
            setOpen(false);
          }}
        />
      </div>
      <div className="flex w-full mt-6 mb-4">
        <MiniDateInput label="From" selectsStart />
        <div className="w-8 h-10 flex items-center justify-center self-end text-uberblue">
          <ArrowRight />
        </div>
        <MiniDateInput label="To" selectsEnd />
      </div>
    </div>
  );
};

export default RangeHeader;
