import { endOfMonth, startOfMonth, startOfYear, subDays } from 'date-fns';
import { createContext, useContext } from 'react';

export const quickRangeOptions = [
  { label: 'All year', value: 'all_year' },
  { label: 'This month', value: 'this_month' },
  { label: 'Last month', value: 'last_month' },
  { label: 'Last 30 days', value: 'last_30_days' },
];

const today = new Date();
const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1);

export const dateRanges = {
  all_year: {
    start: startOfYear(today),
    end: today,
  },
  this_month: {
    start: startOfMonth(today),
    end: endOfMonth(today),
  },
  last_month: {
    start: startOfMonth(lastMonth),
    end: endOfMonth(lastMonth),
  },
  last_30_days: {
    start: subDays(today, 30),
    end: today,
  },
};

export const RangeDateInputContext = createContext(null);

export const useRangeDateInput = () => {
  const context = useContext(RangeDateInputContext);
  if (context === null) {
    throw new Error(
      'useRangeDateInput must be used within RangeDateInputContext.Provider'
    );
  }
  return context;
};
