const ArrowRight = ({ styles, ...rest }) => {
  return (
    <svg
      {...rest}
      width="8"
      className={styles}
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 7L7.35355 6.64645L7.70711 7L7.35355 7.35355L7 7ZM1.35355 0.646447L7.35355 6.64645L6.64645 7.35355L0.646447 1.35355L1.35355 0.646447ZM7.35355 7.35355L1.35355 13.3536L0.646447 12.6464L6.64645 6.64645L7.35355 7.35355Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowRight;
