const Loader = () => {
  return (
    <div className="flex items-center justify-center w-full mt-14 md:mt-[6rem]">
      <svg
        width="250"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="30" y="40" width="7" height="27" rx="4" fill="#E66857">
          <animate
            begin="0s"
            dur="1s"
            attributeName="height"
            values="27;16;27"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin="0s"
            dur="1s"
            values="60;70;60"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="40" y="40" width="7" height="16" rx="4" fill="#26C07F">
          <animate
            begin="0s"
            dur="1s"
            attributeName="height"
            values="16;27;16"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin="0s"
            dur="1s"
            values="70;60;70"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="50" y="40" width="7" height="27" rx="4" fill="#DDA01E">
          <animate
            begin="0s"
            dur="1s"
            attributeName="height"
            values="27;16;27"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin="0s"
            dur="1s"
            values="60;70;60"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
  );
};

export default Loader;
