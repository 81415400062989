import React from 'react';
import { useAuth } from '../../context/Auth';
import { object, mixed } from 'yup';
import { useQueryClient } from 'react-query';
import { useFormik } from 'formik';

import Dash from '../../components/Dash';
import Plus from '../../components/Plus';
import { Toast } from '../../helpers/toastify.helpers';
import { UnderlinedButton as Button } from '../../components/Button';

import { useAppQuery, useAppMutation } from '../../hooks/useAppQuery';

/**
 * getDocs function
 * @param {data} data object
 * @param {type} type of documents
 * @returns jsx
 */
export const getDocs = (data, type) =>
  data?.data.find((item) => item.type === type);

export const validationSchema = object().shape({
  file: mixed()
    .required('File is required')
    .test('invalidFormat', 'File format is not accepted', (value) => {
      if (!value) return;
      return !['audio', 'video'].includes(value.split('/')[0].split(':')[1]);
    }),
});

/**
 * ArticleOfAssociation form Component
 * @returns jsx
 */
const UploadDocs = ({ type, text }) => {
  const {
    auth: { user },
  } = useAuth();
  const [fileName, setFileName] = React.useState('');
  const [values, setValues] = React.useState({
    file: '',
    type: `${type}`,
  });
  const { data } = useAppQuery(`user-documents_${user.id}`, {
    url: `v1/users/${user?.id}/documents`,
  });

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAppMutation(
    {
      url: `v1/users/${user?.id}/documents/upload`,
      data: {
        type: values.type,
        file: values.file,
      },
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(`user-documents_${user.id}`, {
          refetchInactive: true,
        });

        Toast({
          message: `${text} Updated`,
          type: 'success',
        });
      },
      onSettled: (_, error) => {
        setValues({
          file: '',
          type: `${type}`,
        });
        error &&
          Toast({
            message:
              error?.response?.data?.message ??
              error?.message ??
              'Process Failed, Please try again',
            type: 'error',
          });
      },
    }
  );

  /**
   * handleChange
   *  @param {event} event object
   */
  const handleChange = (event) => {
    const { files } = event.target;
    const file = files[0];

    setFileName(file?.name);

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function (event) {
      const { result } = event.target;

      setValues((prevState) => ({
        ...prevState,
        file: result,
      }));
    };
  };

  const formik = useFormik({
    initialValues: values,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async () => {
      await mutate();
      setFileName('');
    },
  });

  React.useEffect(() => {
    if (formik.errors.file) {
      Toast({
        message: formik.errors.file,
        type: 'error',
      });
    }
  }, [formik.errors.file]);

  return (
    <form className="pt-40p xl:w-7/12" onSubmit={formik.handleSubmit}>
      <div className="mb-20">
        <Dash color="#283445" />
        <span className="font-butler uppercase text-16 sm:text-18 leading-19 font-light xl:text-24 text-ebonyclay">
          UPLOAD {text}
        </span>
      </div>
      <p className="mb-20 text-16 font-lato">Select document from files</p>

      <div
        className={`text-12 
       ${getDocs(data, `${type}`) ? '' : 'hidden'}
      `}
      >
        <span
          className={`${
            getDocs(data, `${type}`)?.status === '0'
              ? 'text-punch'
              : 'text-deyork'
          } mr-10p`}
        >
          {getDocs(data, `${type}`)?.status === '0' ? 'Unapproved' : 'Approved'}
        </span>
        <Dash color="#283445" />
        <a
          className="text-kudyblue"
          href={getDocs(data, `${type}`)?.document}
          target="_blank"
          rel="noreferrer"
        >
          View Document
        </a>
      </div>

      <input
        name="file"
        type="file"
        id={`${type}`}
        accept="image/*,application/pdf"
        className="invisible"
        onChange={handleChange}
      />
      <div className="flex justify-end">
        <Button
          type="submit"
          styleClasses="block mr-45"
          loading={isLoading}
          disabled={!formik.isValid}
        >
          Update
        </Button>

        <div>
          <label
            htmlFor={`${type}`}
            className="block cursor-pointer border-b border-ebonyclay"
          >
            Browse Documents <Plus />
          </label>
        </div>
      </div>
      <small className="block text-right opacity-50 mt-2.5">{fileName}</small>
    </form>
  );
};

export default UploadDocs;
