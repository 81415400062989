import MonthlyReport from '../components/MonthlyReport';
import PurgeInvestors from '../components/PurgeInvestors';
import { ClientProfile } from '../utils/assets/Profile';
import { Contract } from '../utils/assets/Contract';
import { Funds } from '../utils/assets/Funds';
import { Purge } from '../utils/assets/Purge';
import { Exchange } from '../utils/assets/Exchange';
import { Kredit } from '../utils/assets/Kredit';
import ExchangeRate from '../pages/ExchangeRate';

export const linkItems = [
  { to: '/dashboard/accounts', label: 'Accounts', icon: ClientProfile },
  { to: '/dashboard/funds', label: 'Funds', icon: Funds },
  { to: '/dashboard/kredit', label: 'Kudy Kredit', icon: Kredit },
  { to: '/dashboard/contracts', label: 'Contracts', icon: Contract },
  {
    to: '/dashboard/exchange-rates',
    label: 'Exchange Rates',
    icon: Exchange,
    component: <ExchangeRate />,
  },
  {
    to: '#',
    label: 'Monthly Report',
    icon: Contract,
    component: <MonthlyReport />,
  },
  {
    to: '#',
    label: 'Purge Clients',
    icon: Purge,
    component: <PurgeInvestors />,
  },
];
