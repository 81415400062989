import Select from 'react-select';
import Label from '../../Label';
import {
  ClearIndicator,
  DropdownIndicator,
  MultiValueRemove,
  Option,
} from './components';
import twMerge from '../../../utils/twMerge';
import { customStyles } from '../../../helpers/reactselect.helpers';

const SelectInput = ({
  label,
  errored,
  errorMessage,
  disabled,
  inputStyle,
  singleValueStyle,
  indicatorsContainerStyle,
  valueContainerStyle,
  className,
  actionButton,
  ...rest
}) => {
  const { name } = rest;

  return (
    <div className="relative mb-2">
      {label && <Label htmlFor={name}>{label}</Label>}
      <div
        className={twMerge(
          disabled && 'cursor-not-allowed',
          actionButton && 'flex items-center gap-2'
        )}
      >
        <div className="flex-1">
          <Select
            inputId={name}
            styles={{
              ...customStyles({
                errored,
                inputStyle,
                singleValueStyle,
                indicatorsContainerStyle,
                valueContainerStyle,
              }),
            }}
            blurInputOnSelect={false}
            hideSelectedOptions={false}
            components={{
              DropdownIndicator,
              MultiValueRemove,
              ClearIndicator,
              Option,
            }}
            className={twMerge('focus:outline-none', className)}
            isDisabled={disabled}
            {...rest}
          />
        </div>
        {actionButton && actionButton}
      </div>
      {errored && (
        <small className="text-valencia truncate">{errorMessage}</small>
      )}
    </div>
  );
};

export default SelectInput;
