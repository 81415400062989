export const ArrowLeft = ({ styles, ...rest }) => {
  return (
    <svg
      {...rest}
      className={styles}
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7L0.646447 6.64645L0.292893 7L0.646447 7.35355L1 7ZM6.64645 0.646447L0.646447 6.64645L1.35355 7.35355L7.35355 1.35355L6.64645 0.646447ZM0.646447 7.35355L6.64645 13.3536L7.35355 12.6464L1.35355 6.64645L0.646447 7.35355Z"
        fill="currentColor"
      />
    </svg>
  );
};
