export const Kredit = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 7H12.5C13.3284 7 14 7.67157 14 8.5C14 8.77614 13.7761 9 13.5 9C13.2239 9 13 8.77614 13 8.5C13 8.22386 12.7761 8 12.5 8H8.5C8.22386 8 8 8.22386 8 8.5V9.5C8 9.77614 8.22386 10 8.5 10H12.5C13.3284 10 14 10.6716 14 11.5V12.5C14 13.3284 13.3284 14 12.5 14H12V14.5C12 14.7761 11.7761 15 11.5 15C11.2239 15 11 14.7761 11 14.5V14H10V14.5C10 14.7761 9.77614 15 9.5 15C9.22386 15 9 14.7761 9 14.5V14H8.5C7.67157 14 7 13.3284 7 12.5C7 12.2239 7.22386 12 7.5 12C7.77614 12 8 12.2239 8 12.5C8 12.7761 8.22386 13 8.5 13H12.5C12.7761 13 13 12.7761 13 12.5V11.5C13 11.2239 12.7761 11 12.5 11H8.5C7.67157 11 7 10.3284 7 9.5V8.5C7 7.67157 7.67157 7 8.5 7H9V6.5C9 6.22386 9.22386 6 9.5 6C9.77614 6 10 6.22386 10 6.5V7H11V6.5C11 6.22386 11.2239 6 11.5 6C11.7761 6 12 6.22386 12 6.5V7ZM17 17V5.5C17 4.67157 16.3284 4 15.5 4H5.5C4.67157 4 4 4.67157 4 5.5V19C4 19.5523 4.44772 20 5 20C5.55228 20 6 19.5523 6 19V17.5C6 17.2239 6.22386 17 6.5 17H17ZM18 17H20.5C20.7761 17 21 17.2239 21 17.5V18.5C21 19.8807 19.8807 21 18.5 21H5C3.89543 21 3 20.1046 3 19V5.5C3 4.11929 4.11929 3 5.5 3H15.5C16.8807 3 18 4.11929 18 5.5V17ZM18.5 20C19.3284 20 20 19.3284 20 18.5V18H7V19C7 19.3643 6.90261 19.7058 6.73244 20H18.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
