const CloseIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99996 7.5286L10.7643 4.7643C10.8944 4.63413 11.1055 4.63413 11.2357 4.7643C11.3658 4.89448 11.3658 5.10553 11.2357 5.23571L8.47136 8.00001L11.2357 10.7643C11.3658 10.8945 11.3658 11.1055 11.2357 11.2357C11.1055 11.3659 10.8944 11.3659 10.7643 11.2357L7.99996 8.47141L5.23566 11.2357C5.10549 11.3659 4.89443 11.3659 4.76426 11.2357C4.63408 11.1055 4.63408 10.8945 4.76426 10.7643L7.52856 8.00001L4.76426 5.23571C4.63408 5.10553 4.63408 4.89448 4.76426 4.7643C4.89443 4.63413 5.10549 4.63413 5.23566 4.7643L7.99996 7.5286Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseIcon;
