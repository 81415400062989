import React from 'react';

/**
 * Search Icon component
 * @returns jsx
 */
const SearchIcon = () => (
  <svg
    className="absolute inset-y-0 right-0"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5746 14.2929L7.80761 9.49496C5.65143 10.9269 2.76862 10.4839 1.13493 8.46968C-0.498755 6.4554 -0.353301 3.52331 1.47161 1.68292C3.29942 -0.154934 6.21232 -0.301998 8.21361 1.34253C10.2149 2.98706 10.6552 5.88954 9.23261 8.0604L13.9976 12.8604L12.5746 14.2929ZM5.03161 2.22653C3.59086 2.22561 2.35009 3.24937 2.06809 4.67173C1.7861 6.09409 2.54142 7.51891 3.87214 8.07485C5.20285 8.63078 6.73835 8.163 7.5396 6.95757C8.34084 5.75213 8.18468 4.14478 7.16661 3.11848C6.60186 2.54601 5.83306 2.22482 5.03161 2.22653Z"
      fill="#2E3A59"
    />
  </svg>
);

export default SearchIcon;
