import { components } from 'react-select';
import isEqual from 'lodash.isequal';
import CheckBox from './CheckBox';
import ArrowDown from '../../../utils/assets/ArrowDown';
import CloseIcon from './CloseIcon';

export const DropdownIndicator = () => <ArrowDown width={24} height={24} />;

export const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon />
    </components.MultiValueRemove>
  );
};

export const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <CloseIcon width={24} height={24} />
    </components.ClearIndicator>
  );
};

export const Option = ({ children, ...props }) => {
  const { isMulti, isSelected, getValue, setValue, data } = props;

  const handleChange = (event) => {
    const isChecked = event.target.checked;

    const updatedValue = isChecked
      ? [...getValue(), data]
      : getValue().filter((option) => !isEqual(option, data));

    setValue(
      updatedValue,
      isChecked ? 'select-option' : 'deselect-option',
      data
    );
  };

  return isMulti ? (
    <components.Option {...props}>
      <div onClick={(e) => e.stopPropagation()}>
        <CheckBox checked={isSelected} onChange={handleChange}>
          {children}
        </CheckBox>
      </div>
    </components.Option>
  ) : (
    <components.Option {...props}>{children}</components.Option>
  );
};
