import { useCallback, useEffect, useRef } from 'react';

const DEFAULT_EVENTS = ['mousedown', 'touchstart'];

const useClickOutside = (handler, events = DEFAULT_EVENTS) => {
  const ref = useRef(null);

  const listener = useCallback(
    (event) => {
      const target = event.target;
      if (ref.current && !ref.current.contains(target)) {
        handler();
      }
    },
    [handler]
  );

  useEffect(() => {
    events.forEach((event) => document.addEventListener(event, listener));
    return () => {
      events.forEach((event) => document.removeEventListener(event, listener));
    };
  }, [events, listener]);

  return ref;
};
export default useClickOutside;
