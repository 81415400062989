import { lazy } from 'react';

const Accounts = lazy(() => import('../pages/Accounts'));
const AdminProfile = lazy(() => import('../pages/AdminProfile'));
const ExchangeRate = lazy(() => import('../pages/ExchangeRate'));
const Funds = lazy(() => import('../pages/Funds'));
const ContractAgreement = lazy(() => import('../pages/ContractAgreement'));
const Kredit = lazy(() => import('../pages/Kredit'));
const FundDetails = lazy(() => import('../components/Funds/FundDetails'));
const CreateFund = lazy(() => import('../components/Funds/CreateFund'));
const Account = lazy(() => import('../pages/Accounts/Account'));

export const dashboardRoutes = [
  {
    path: '',
    Component: Accounts,
  },
  {
    path: 'accounts',
    Component: Accounts,
  },
  {
    path: 'profile',
    Component: AdminProfile,
  },
  {
    path: 'exchange-rates',
    Component: ExchangeRate,
  },
  {
    path: 'funds',
    Component: Funds,
  },
  {
    path: 'kredit',
    Component: Kredit,
  },
  {
    path: 'contracts',
    Component: ContractAgreement,
  },
  {
    path: 'funds/create-fund',
    Component: CreateFund,
  },
  {
    path: 'funds/details/:fundId',
    Component: FundDetails,
  },
  {
    path: 'accounts/:accountId',
    Component: Account,
  },
];
