import { format } from 'date-fns';

/**
 * formatDateForDigest method
 * @param {date} date unformatted date
 * @returns string
 */
export const formatDateForDigest = (date) => {
  if (date) {
    return format(new Date(date), 'MMM, yyyy');
  } else {
    return 'N/A';
  }
};
export const monthDayYear = (date) =>
  date ? format(new Date(date), 'MMMM d, yyyy') : 'N/A';

export const monthAndYear = (date) =>
  date ? format(new Date(date), 'MMMM yyyy') : 'N/A';

/**
 * formatDateForFundCycleList method
 * @param {date} date unformatted date
 * @returns string
 */
export const formatDateForFundCycleList = (date) => {
  if (date) {
    return format(new Date(date), 'dd-MM-yyyy');
  } else {
    return 'N/A';
  }
};
/**
 * convertToEditableDate method
 * @param {date} date unformatted date
 * @returns string
 */
export const convertToEditableDate = (date) => {
  if (date) {
    return format(new Date(date), 'yyyy-MM-dd');
  } else {
    return 'N/A';
  }
};
