import DatePicker from 'react-datepicker';
import twMerge from '../../../utils/twMerge';
import { useRangeDateInput } from './RangeDateInputContext';

const MiniDateInput = ({ label, selectsStart, selectsEnd }) => {
  const { startDate, setStartDate, endDate, setEndDate } = useRangeDateInput();
  const rangeDate = selectsStart ? startDate : endDate;
  const setRangeDate = selectsStart ? setStartDate : setEndDate;

  return (
    <div className="w-[124px]">
      <label className="text-xs text-shuttlegray block mb-1">{label}</label>
      <DatePicker
        open={false}
        className={twMerge(
          'focus:outline-none border-0.5 border-loblolly placeholder-loblolly focus:border-jungleGreen w-full py-3 px-2 rounded-sm cursor-pointer'
        )}
        dateFormat="dd/MM/yyyy"
        placeholderText={selectsStart ? 'Start Date' : 'End Date'}
        selected={rangeDate}
        onChange={(date) => setRangeDate(date)}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        startDate={startDate}
        endDate={endDate}
        minDate={selectsEnd ? startDate : undefined}
      />
    </div>
  );
};

export default MiniDateInput;
