import { jwtDecode } from 'jwt-decode';

const isTokenExpired = (token) => {
  const { exp } = jwtDecode(token);
  const isExpired = exp < Date.now() / 1000;
  if (isExpired) {
    localStorage.removeItem('jwtToken');
  }
  return isExpired;
};

export const getToken = () => {
  const token = localStorage.getItem('jwtToken');
  return token && !isTokenExpired(token) ? token : null;
};
