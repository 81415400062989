import twMerge from '../../utils/twMerge';
import getSymbolFromCurrency from 'currency-symbol-map';
import CurrencyInput from 'react-currency-input-field';

const MoneyInput = ({
  label,
  errored,
  errorMessage,
  currency,
  className,
  containerClassName,
  disabled,
  ...rest
}) => {
  const { name } = rest;
  return (
    <div className={twMerge('relative mb-3', containerClassName)}>
      {label && (
        <label className="mb-2 block text-blueWood" htmlFor={name}>
          {label}
        </label>
      )}
      <CurrencyInput
        allowNegativeValue={false}
        className={twMerge(
          'w-full border-0.5 border-loblolly placeholder:text-14 placeholder-loblolly focus:outline-none focus:border-jungleGreen rounded-sm py-3 px-2 bg-transparent text-ebonyclay',
          errored && 'mb-5 border-valencia focus:border-valencia',
          disabled &&
            'bg-white border-loblolly text-ebonyclay cursor-not-allowed',
          className
        )}
        decimalsLimit={2}
        prefix={getSymbolFromCurrency(currency ?? '')}
        disabled={disabled}
        {...rest}
      />
      {errored && (
        <small className="absolute left-0 top-[50px] text-valencia truncate">
          {errorMessage}
        </small>
      )}
    </div>
  );
};
export default MoneyInput;
