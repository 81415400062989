const Rate = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.0001 8H14.5001C15.3285 8 16.0001 8.67157 16.0001 9.5C16.0001 9.77614 15.7762 10 15.5001 10C15.2239 10 15.0001 9.77614 15.0001 9.5C15.0001 9.22386 14.7762 9 14.5001 9H9.50007C9.22393 9 9.00007 9.22386 9.00007 9.5V10.6327C9.00007 10.8815 9.18304 11.0925 9.42936 11.1277L14.7122 11.8824C15.4512 11.9879 16.0001 12.6208 16.0001 13.3673V14.5C16.0001 15.3284 15.3285 16 14.5001 16H14.0001V16.5C14.0001 16.7761 13.7762 17 13.5001 17C13.2239 17 13.0001 16.7761 13.0001 16.5V16H11.0001V16.5C11.0001 16.7761 10.7762 17 10.5001 17C10.2239 17 10.0001 16.7761 10.0001 16.5V16H9.50007C8.67165 16 8.00007 15.3284 8.00007 14.5C8.00007 14.2239 8.22393 14 8.50007 14C8.77622 14 9.00007 14.2239 9.00007 14.5C9.00007 14.7761 9.22393 15 9.50007 15H14.5001C14.7762 15 15.0001 14.7761 15.0001 14.5V13.3673C15.0001 13.1185 14.8171 12.9075 14.5708 12.8723L9.28794 12.1176C8.54897 12.0121 8.00007 11.3792 8.00007 10.6327V9.5C8.00007 8.67157 8.67165 8 9.50007 8H10.0001V7.5C10.0001 7.22386 10.2239 7 10.5001 7C10.7762 7 11.0001 7.22386 11.0001 7.5V8H13.0001V7.5C13.0001 7.22386 13.2239 7 13.5001 7C13.7762 7 14.0001 7.22386 14.0001 7.5V8ZM3.00007 12C3.00007 7.02944 7.02951 3 12.0001 3C16.9706 3 21.0001 7.02944 21.0001 12C21.0001 16.9706 16.9706 21 12.0001 21C10.3532 21 8.77065 20.5564 7.38919 19.7307L3.65819 20.9743C3.2356 21.1152 2.85365 20.6748 3.05286 20.2764L4.61698 17.1482C3.57158 15.6519 3.00007 13.8686 3.00007 12ZM7.29443 18.7082C7.43704 18.6606 7.59348 18.6798 7.72041 18.7603C8.99017 19.5659 10.4624 20 12.0001 20C16.4184 20 20.0001 16.4183 20.0001 12C20.0001 7.58172 16.4184 4 12.0001 4C7.5818 4 4.00007 7.58172 4.00007 12C4.00007 13.7565 4.56703 15.4257 5.59959 16.8002C5.71343 16.9517 5.7318 17.1546 5.64704 17.3241L4.48712 19.6439L7.29443 18.7082Z"
        fill="#BCC2CC"
      />
    </svg>
  );
};

export default Rate;
