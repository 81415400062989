import { Fragment } from 'react';
import { formatDateTime } from '../../utils/formatValues';
import Kebab from '../../utils/assets/Kebab';
import Dropdown from '../../components/Ui/Dropdown';
import { useAppMutation } from '../../hooks/useAppQuery';
import { Toast } from '../../helpers/toastify.helpers';
import { useQueryClient } from 'react-query';

const ExchangeRateRow = ({ data }) => {
  const queryClient = useQueryClient();
  const { mutate: deleteExchangeRate } = useAppMutation(
    {
      url: `v1/exchanges/${data.id}`,
      method: 'DELETE',
    },
    {
      onSettled: async (_, error) => {
        if (error) {
          const { message } = error.response.data;

          Toast({
            message,
            type: 'error',
          });

          queryClient.setQueryData('current-rate', {
            data: { buy: 0, sell: 0 },
          });
        }
        Toast({
          message: `Exchange rate deleted successfully`,
          type: 'success',
        });

        await Promise.all([
          queryClient.invalidateQueries('current-rate'),
          queryClient.invalidateQueries('exchange-rates'),
        ]);
      },
    }
  );

  const handleDropdownSelection = (selectedAction) => {
    if (selectedAction.value === 'delete') {
      deleteExchangeRate();
    }
  };

  return (
    <Fragment key={data.created_at}>
      <div className="cursor-pointer flex-0 p-4 border-b">
        {formatDateTime(data.created_at)}
      </div>
      <div className="cursor-pointer flex-0 p-4 border-b">{data.buy}</div>
      <div className="cursor-pointer flex-0 p-4 border-b">{data.sell}</div>
      <div
        className={`flex-2 flex p-4 justify-end border-b bg-white border-gray-300`}
      >
        <Dropdown
          onChange={handleDropdownSelection}
          options={[
            { label: 'Delete Rate', value: 'delete', textColor: '#D43A42' },
          ]}
          icon={Kebab}
        />
      </div>
    </Fragment>
  );
};

export default ExchangeRateRow;
