import React from 'react';
import PropTypes from 'prop-types';
import { stringToAvatar } from '../utils/stringToAvatar.js';

/**
 * @returns Avator Component
 */
const Avatar = ({ user, onClick }) => {
  return (
    <img
      onClick={onClick}
      className="w-10 h-10 rounded-full inline-block"
      src={stringToAvatar(user)}
      alt="Profile Avatar"
    />
  );
};

Avatar.propTypes = { avatar: PropTypes.string };

export default Avatar;
