import { theme } from 'twin.macro';
import styled from 'styled-components';

const $dp = '.react-datepicker';

const DatePickerContainer = styled.div`
  .react-datepicker-popper[data-placement^='top'] .react-datepicker {
    animation: slideUp 0.2s ease-in-out;
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker {
    animation: slideDown 0.2s ease-in-out;
  }
  .react-datepicker {
    width: 312px;
    border: none;
    border-radius: 2px;
    padding: 16px;
    padding-bottom: 24px;
    font-family: 'Lato', sans-serif;
    box-shadow:
      0px 8px 8px -4px rgba(16, 24, 40, 0.03),
      0px 20px 24px -4px rgba(16, 24, 40, 0.08);

    &-wrapper {
      width: 100%;
    }
    &__month,
    &__day,
    &__day-name,
    &__month ${$dp}__month-text, &__year,
    &__year ${$dp}__year-text {
      margin: 0;
      font-size: 14px;
      line-height: 16px;
    }
    &__month-container {
      float: none;
    }
    &__header {
      background-color: #ffffff;
      border-bottom: none;
      padding: 0;
    }
    &__day-names,
    &__week,
    &__month-wrapper {
      display: flex;
      width: 100%;
      align-items: center;
    }
    &__day-names {
      margin-bottom: 4px;
    }
    &__day-name,
    &__day {
      width: 40px;
    }
    &__day-name {
      color: ${theme`colors.uberblue`};
    }
    &__day {
      height: 32px;
    }
    &__month ${$dp}__month-text, &__year ${$dp}__year-text {
      width: 78px;
    }
    &__monthPicker > * + * {
      margin-top: 16px;
    }
    &__month-wrapper,
    &__year-wrapper {
      justify-content: space-between;
    }
    &__year-wrapper {
      max-width: 100%;
      display: grid;
      grid-template-columns: repeat(3, auto);
      gap: 16px;
    }
    &__year--selecting-range
      ${$dp}__year-text--in-range:not(
        ${$dp}__year-text--in-selecting-range
      ) {
      background-color: ${theme`colors.jungleGreen.20`};
      color: ${theme`colors.ebonyclay`};
    }
    &__year--selecting-range {
      ${$dp}__year-text--range-start:not(${$dp}__year-text--in-selecting-range),
  ${$dp}__year-text--range-end:not(${$dp}__year-text--in-selecting-range) {
        background-color: ${theme`colors.jungleGreen.DEFAULT`};
        color: white;
      }
    }
    &__day,
    &__month ${$dp}__month-text, &__year ${$dp}__year-text {
      color: ${theme`colors.ebonyclay`};
      padding: 8px;
      border-radius: 0;
      &:hover {
        border-radius: 0;
        background-color: ${theme`colors.jungleGreen.20`};
        color: ${theme`colors.jungleGreen.DEFAULT`};
      }
      &--today {
        border: 0.5px solid ${theme`colors.jungleGreen.DEFAULT`};
      }
      &--keyboard-selected {
        background-color: inherit;
      }
      &--outside-month {
        color: ${theme`colors.uberblue`};
        &:hover {
          color: ${theme`colors.jungleGreen.DEFAULT`};
        }
      }
      &--in-range,
      &--in-selecting-range:not(&--in-range) {
        background-color: ${theme`colors.jungleGreen.20`};
      }

      &--selected,
      &--selected:hover,
      &--range-start,
      &--range-start:hover,
      &--range-end,
      &--range-end:hover {
        background-color: ${theme`colors.jungleGreen.DEFAULT`};
        color: white;
      }

      &--disabled,
      &--disabled:hover {
        color: ${theme`colors.loblolly`};
        &:hover {
          background-color: ${theme`colors.mischka`};
        }
      }
    }

    &-popper[data-placement^='bottom'] {
      padding-top: 4px;
    }
    &-popper[data-placement^='top'] {
      padding-bottom: 4px;
    }
  }
`;

export default DatePickerContainer;
