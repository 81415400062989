export const kreditReducer = (state, action) => {
  switch (action.type) {
    case 'SELECT_KREDIT_PROFILES':
      return { selectedInfo: 'kreditProfiles' };
    case 'SELECT_KREDIT_TRANSACTIONS':
      return { selectedInfo: 'Transactions' };
    default:
      return state;
  }
};
