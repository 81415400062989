/**
 * Loader component
 * @returns jsx
 */
const Loader = () => (
  <div className="h-screen w-screen bg-ebonyclay">
    <div className="absolute left-1/2 -ml-125 top-1/4 ">
      <svg
        width="250"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="30" y="40" width="8" height="27" rx="4" fill="#EDEAE6">
          <animate
            begin="0s"
            dur="1s"
            attributeName="height"
            values="27;19;27"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin="0s"
            dur="1s"
            values="60;70;60"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="40" y="40" width="8" height="19" rx="4" fill="#6FCF97">
          <animate
            begin="0s"
            dur="1s"
            attributeName="height"
            values="19;27;19"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin="0s"
            dur="1s"
            values="70;60;70"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="50" y="40" width="8" height="27" rx="4" fill="#EDEAE6">
          <animate
            begin="0s"
            dur="1s"
            attributeName="height"
            values="27;19;27"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin="0s"
            dur="1s"
            values="60;70;60"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
      </svg>

      <div className="text-white font-butler uppercase text-24">
        Kudy Financials Sarl
      </div>
    </div>
  </div>
);

export default Loader;
