import { format, parse } from 'date-fns';

export const formatDate = (date) => {
  if (!date || date === 'Invalid date') return 'N/A';
  const dateValue = parse(date.split(' ')[0], 'yyyy-MM-dd', new Date());
  return format(dateValue, 'dd.MM.yy');
};

export const formatStatementDate = (date) => {
  if (!date || date === 'Invalid date') return 'N/A';
  return format(new Date(date), 'dd LLL yyyy');
};

export const formatDateTime = (datetime) => {
  const date = new Date(datetime);
  if (!date || date === 'Invalid date') return 'N/A';
  return format(date, 'dd-MMM-yyyy hh:mmaaa');
};

export const formatMoney = (money = '', currency = 'USD') => {
  return new Intl.NumberFormat(currency === 'USD' ? 'en-US' : 'en-NG', {
    ...(currency && { currency: currency, style: 'currency' }),
  }).format(money);
};

export const formatDuration = (value, unit) => {
  return Number(value) > 1 ? `${unit}s` : unit;
};
