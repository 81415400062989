import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as Calendar } from '../../../assets/calendar.svg';
import twMerge from '../../../utils/twMerge';

import { useRef, useState } from 'react';
import DatePickerContainer from './DatePickerContainer';
import CustomHeader from './CustomHeader';

const DateInput = ({
  className,
  label,
  errored,
  errorMessage,
  innerRef,
  ...rest
}) => {
  const { name, disabled, selectsRange } = rest;

  const [view, setView] = useState('day');
  const showMonthYearPicker = view === 'month';
  const showYearPicker = view === 'year';

  const handleSelect = () => {
    if (view === 'year') {
      setView('month');
    } else if (view === 'month') {
      setView('day');
    }
  };

  const dateInputRef = useRef(null);
  const ref = innerRef || dateInputRef;
  const setFocus = ref.current?.setFocus;

  return (
    <DatePickerContainer className="relative mb-2">
      {label && (
        <label className="mb-2 block text-blueWood" htmlFor={name}>
          {label}
        </label>
      )}
      <div className={twMerge(disabled && 'cursor-not-allowed')}>
        <DatePicker
          ref={ref}
          className={twMerge(
            'focus:outline-none bg-transparent border-0.5 border-loblolly placeholder-loblolly focus:border-jungleGreen w-full disabled:bg-gallery disabled:text-loblolly disabled:border-loblolly py-3 px-2 rounded-sm cursor-pointer relative text-ebonyclay font-lato',
            errored && 'mb-5 border-valencia focus:border-valencia',
            className
          )}
          showPopperArrow={false}
          fixedHeight
          dateFormat="dd/MM/yyyy"
          placeholderText="dd/mm/yyyy"
          useWeekdaysShort
          showMonthYearPicker={showMonthYearPicker}
          showFullMonthYearPicker={showMonthYearPicker}
          showYearPicker={showYearPicker}
          shouldCloseOnSelect={view === 'day'}
          onSelect={handleSelect}
          onCalendarClose={() => setView('day')}
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            increaseYear,
            decreaseYear,
          }) => (
            <CustomHeader
              selectsRange={selectsRange}
              date={date}
              decreaseMonth={decreaseMonth}
              decreaseYear={decreaseYear}
              increaseMonth={increaseMonth}
              increaseYear={increaseYear}
              setView={setView}
              showMonthYearPicker={showMonthYearPicker}
              showYearPicker={showYearPicker}
            />
          )}
          {...rest}
        />
        <Calendar
          className={twMerge(
            'absolute right-2 cursor-pointer',
            label ? 'top-11' : 'top-[8.5px]'
          )}
          onClick={() => setFocus?.()}
        />
      </div>
      {errored && (
        <small
          className={'absolute left-0 -bottom-[1px] text-valencia truncate'}
        >
          {errorMessage}
        </small>
      )}
    </DatePickerContainer>
  );
};

export default DateInput;
