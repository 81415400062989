import React from 'react';

const Kebab = ({ props }) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.75 7.5C12.1642 7.5 12.5 7.16421 12.5 6.75C12.5 6.33579 12.1642 6 11.75 6C11.3358 6 11 6.33579 11 6.75C11 7.16421 11.3358 7.5 11.75 7.5Z"
        stroke="#283445"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.75 13C12.1642 13 12.5 12.6642 12.5 12.25C12.5 11.8358 12.1642 11.5 11.75 11.5C11.3358 11.5 11 11.8358 11 12.25C11 12.6642 11.3358 13 11.75 13Z"
        stroke="#283445"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.75 18.5C12.1642 18.5 12.5 18.1642 12.5 17.75C12.5 17.3358 12.1642 17 11.75 17C11.3358 17 11 17.3358 11 17.75C11 18.1642 11.3358 18.5 11.75 18.5Z"
        stroke="#283445"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Kebab;
