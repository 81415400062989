import twMerge from '../utils/twMerge';

const Label = ({ className, ...props }) => {
  return (
    <label
      className={twMerge('mb-2 block text-blueWood', className)}
      {...props}
    />
  );
};

export default Label;
