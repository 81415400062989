import twMerge from '../../../utils/twMerge';
import { format, getYear } from 'date-fns';
import {
  ArrowDoubleLeft,
  ArrowDoubleRight,
  ArrowLeft,
  ArrowRight,
} from './Arrows';
import RangeHeader from './RangeHeader';

const CalendarButton = ({ className, ...props }) => (
  <button
    type="button"
    className={twMerge('hover:text-jungleGreen', className)}
    {...props}
  />
);

const CustomHeader = ({
  selectsRange,
  date,
  showMonthYearPicker,
  showYearPicker,
  setView,
  increaseMonth,
  increaseYear,
  decreaseMonth,
  decreaseYear,
}) => {
  const endYear = Math.ceil(getYear(date) / 12) * 12;
  const startYear = endYear - 11;
  const isMonthOrYearView = showMonthYearPicker || showYearPicker;

  return (
    <>
      {selectsRange && <RangeHeader />}
      <div
        className={twMerge(
          'flex w-full items-center mb-4',
          isMonthOrYearView && 'mb-2'
        )}
      >
        <div className="flex">
          <CalendarButton onClick={decreaseYear} className="text-uberblue">
            <ArrowDoubleLeft />
          </CalendarButton>
          {!isMonthOrYearView && (
            <CalendarButton onClick={decreaseMonth} className="text-uberblue">
              <ArrowLeft />
            </CalendarButton>
          )}
        </div>
        <div className="text-base flex-1 flex gap-1 justify-center text-ebonyclay">
          {!isMonthOrYearView && (
            <CalendarButton onClick={() => setView('month')}>
              {format(date, 'MMM')}
            </CalendarButton>
          )}
          {!showYearPicker ? (
            <CalendarButton onClick={() => setView('year')}>
              {format(date, 'yyyy')}
            </CalendarButton>
          ) : (
            <span>{`${startYear}-${endYear}`}</span>
          )}
        </div>
        <div className="flex">
          {!isMonthOrYearView && (
            <CalendarButton onClick={increaseMonth} className="text-uberblue">
              <ArrowRight />
            </CalendarButton>
          )}
          <CalendarButton onClick={increaseYear} className="text-uberblue">
            <ArrowDoubleRight />
          </CalendarButton>
        </div>
      </div>
    </>
  );
};

export default CustomHeader;
