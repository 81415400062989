/**
 * userReducer
 * @param {Object} state state
 * @param {Object} action action type and payload
 * @returns {Object} object
 */
const userReducer = (state = {}, { type, payload }) => {
  let userId = localStorage.getItem('userId');
  switch (type) {
    case 'LOGGED_IN':
      return {
        ...state,
        token: payload.token,
        user: {
          id: userId,
          ...payload.user,
        },
        loggedIn: true,
        authenticating: false,
      };
    case 'LOGGED_OUT':
      return {
        ...state,
        token: '',
        user: null,
        loggedIn: false,
        authenticating: false,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: payload,
      };
    case 'SET_AUTH_ERROR':
      return {
        ...state,
        error: payload.error,
        authenticating: false,
        loggedIn: false,
        user: null,
      };
    case 'SET_USER':
      return {
        ...state,
        user: payload,
      };
    case 'CLEAR_AUTH_ERROR':
      return {
        ...state,
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

export default userReducer;
